input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.list-filters{
  background-color: rgb(184, 184, 184);
  
  margin: 0 0 5px 0;
  padding: 0px 5px;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: #444;
  min-height: 33px;
}

.MuiFormControlLabel-label {
font-size: 13px !important;

}

.list-filter {
  border-radius: 50px;
  line-height: 20px;
  min-height: 25px;
  max-height: 25px;
  background-color: whitesmoke;
  padding-inline: 5px;
  
  border: 1px solid #777;
  flex-grow: 1;
}

.list-filter:focus {
  outline: 1px solid #777;
  background-color:white
}

.list-counter {
  margin-left: 20px;
  
  font-size: 13px !important;
  background-color: rgb(49, 49, 255);
  padding-inline: 8px;
  padding-block: 0px;
  color: white;
  border-radius: 50px;
  line-height: 20px;
  max-height: 20px;
}

.list-clear-filter{

  margin-left: -22.5px;
  margin-top: 1px;
  font-size: 10px;
  font-weight: 800;
  color: rgb(81, 81, 81);
  line-height: 25px;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  user-select: none;
  background-color: rgb(208, 208, 208);
  line-height: 16px;
  max-height: 16px;
  padding-inline: 5px;
  border-radius: 50%;
}

::placeholder {
  opacity: .7
}
